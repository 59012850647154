import { configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import authReducer from "./auth-slice";
import invoiceReducer from "./invoice-slice";
import smartActionReducer from "./smart-action-slice";
import smartAgreementReducer from "./smart-agreement-slice";
import uiReducer from "./ui-slice";

enableMapSet();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    invoices: invoiceReducer,
    smartActions: smartActionReducer,
    smartAgreements: smartAgreementReducer,
    ui: uiReducer,
  },
});
