import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  key: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setKey: (state, action) => {
      state.key = action.payload;
    },
  },
});

export const selectKey = (state) => state.auth.key;

export const { setKey } = authSlice.actions;

export default authSlice.reducer;
