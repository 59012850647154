import axios from "axios";

const EAI_API_KEY = "ntZAC3BLuxasJblpiuP6kOC406FNkc1ihjeLIA67";

const createSmartAgreement = async () => {
  const { data } = await axios.post(
    "/api/auth/smart-agreement",
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const saveSmartAgreement = async (smartAgreement) => {
  const { data } = await axios.put(
    "/api/auth/smart-agreement",
    smartAgreement,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const deploySmartAgreement = async (smartAgreement) => {
  const { data } = await axios.put(
    `/api/auth/smart-agreement/deploy`,
    smartAgreement,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const undeploySmartAgreement = async (id) => {
  const { data } = await axios.put(
    `/api/auth/smart-agreement/${id}/undeploy`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const getSmartAgreements = async () => {
  const { data } = await axios.get("/api/auth/smart-agreements", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

const deleteSmartAgreement = async (id) => {
  await axios.delete(`/api/auth/smart-agreement/${id}`);
};

const getSmartActions = async () => {
  const { data } = await axios.get("/api/auth/smart-actions", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

const executeSmartAction = async (smartAgreementId, invoice) => {
  const { data } = await axios.post(
    `/api/auth/smart-agreement/${smartAgreementId}/execute`,
    invoice,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const createInvoice = async (smartAgreementId) => {
  const { data } = await axios.post(
    "/api/auth/invoice",
    {
      smartAgreementId,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const updateInvoice = async (invoice) => {
  const { data } = await axios.put("/api/auth/invoice", invoice, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

const getInvoices = async () => {
  const { data } = await axios.get("/api/auth/invoices", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

const getCloudStorageSignedURL = async ({ fileName, contentType }) => {
  const {
    data: { url, publicUrl, fileName: responseFileName },
  } = await axios.post(
    "/api/auth/upload",
    {
      fileName,
      contentType,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return { url, publicUrl, fileName: responseFileName };
};

const uploadFile = async (url, file) => {
  await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};

const downloadFile = async (file) => {
  const {
    data: { url },
  } = await axios.get(`/api/auth/download/${file}`, {
    headers: { "Content-Type": "application/json" },
  });
  return url;
};

const getOrganization = async (id) => {
  const { data } = await axios.get(`/api/auth/organization/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const getInvoicesBySmartAgreementId = async (id) => {
  const { data } = await axios.get(`/api/auth/smart-agreement/${id}/invoices`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};

const getSmartAgreementsWithPendingInvoices = async () => {
  const { data } = await axios.get(
    `/api/auth/smart-agreements-with-pending-invoices`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const deleteSmartAgreementAndInvoices = async (id) => {
  const response = await axios.delete(`/api/auth/smart-agreement/${id}`);
};

const demoValidateInvoices = async (formData) => {
  const { data } = await axios.post(
    "/api/auth/demo-validate-invoices",
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return data;
};

const getGasPrices = async () => {
  const { data } = await axios.get(
    `https://api.eia.gov/v2/petroleum/pri/gnd/data/?frequency=weekly&data[0]=value&facets[product][]=EPD2D&facets[series][]=EMD_EPD2D_PTE_R1Z_DPG&start=2024-08-01&sort[0][column]=period&sort[0][direction]=desc&offset=0&length=5000&api_key=${EAI_API_KEY}`
  );
  return data;
};

export {
  createInvoice,
  createSmartAgreement,
  deleteSmartAgreement,
  deleteSmartAgreementAndInvoices,
  deploySmartAgreement,
  downloadFile,
  executeSmartAction,
  getCloudStorageSignedURL,
  getInvoices,
  getInvoicesBySmartAgreementId,
  getOrganization,
  getSmartActions,
  getSmartAgreements,
  getSmartAgreementsWithPendingInvoices,
  saveSmartAgreement,
  undeploySmartAgreement,
  updateInvoice,
  uploadFile,
  demoValidateInvoices,
  getGasPrices,
};
