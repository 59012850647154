import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawerOpen: false,
  dimensions: null,
  tabIndex: 2,
  userDrawerOpen: false,
  organization: null,
  user: {},
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setUserDrawerOpen: (state, action) => {
      state.userDrawerOpen = action.payload;
    },
    setDimensions: (state, action) => {
      state.dimensions = action.payload;
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const selectDrawerOpen = (state) => state.ui.drawerOpen;
export const selectUserDrawerOpen = (state) => state.ui.userDrawerOpen;
export const selectDimensions = (state) => state.ui.dimensions;
export const selectTabIndex = (state) => state.ui.tabIndex;
export const selectOrganization = (state) => state.ui.organization;
export const selectUser = (state) => state.ui.user;

export const {
  setDrawerOpen,
  setUserDrawerOpen,
  setDimensions,
  setTabIndex,
  setOrganization,
  setUser,
} = uiSlice.actions;

export default uiSlice.reducer;
