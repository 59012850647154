import axios from "axios";
import { useEffect } from "react";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { selectKey, setKey } from "./redux/auth-slice";
// import DashboardView from "./views/DashboardView";
import DemoView from "./views/DemoView";
import LandingPageView from "./views/LandingPageView";

const App = () => {
  const dispatch = useDispatch();
  const key = useSelector(selectKey);

  useEffect(() => {
    // enable admin view, if provided the correct key
    const queryParameters = new URLSearchParams(window.location.search);
    console.log(queryParameters);
    const keyParam = queryParameters.get("key");
    console.log(keyParam);
    if (!!keyParam) {
      console.log("!!keyParam");
      fetch(`/api/login/${keyParam.trim()}`)
        .then((response) => response.json())
        .then(({ isValid }) => {
          if (isValid) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${keyParam}`;
            dispatch(setKey(keyParam));
          }
        });
    }
  }, [dispatch]);

  return key ? <DemoView /> : <LandingPageView />;
};

export default App;
