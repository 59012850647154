import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { executeSmartAction } from "../api";

const initialState = {
  smartAgreement: null,
  invoice: null,
  invoices: [],
  modalVisible: false,
  invoicesPaymentHistory: [],
  pendingInvoices: [],
  pendingInvoiceIndex: 0,
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setSmartAgreement: (state, action) => {
      state.smartAgreement = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    addInvoiceFile: (state, action) => {
      const { fieldName, entry } = action.payload;
      const files = `${fieldName}-files`;
      state.invoice.data[files] = state.invoice.data[files] || [];
      // correlate by name / overwrite
      state.invoice.data[files] = state.invoice.data[files].filter(
        ({ displayName }) => displayName !== entry.displayName
      );
      state.invoice.data[files].push(entry);
    },
    removeInvoiceFile: (state, action) => {
      const { fieldName, entry } = action.payload;
      const files = `${fieldName}-files`;
      state.invoice.data[files] = state.invoice.data[files].filter(
        ({ displayName }) => displayName !== entry.displayName
      );
    },
    setModalVisible: (state, action) => {
      state.modalVisible = action.payload;
    },
    setInvoicesPaymentHistory: (state, action) => {
      state.invoicesPaymentHistory = action.payload;
    },
    setPendingInvoices: (state, action) => {
      state.pendingInvoices = action.payload;
      state.pendingInvoiceIndex = 0;
    },
    incrementPendingInvoicesIndex: (state, action) => {
      state.pendingInvoiceIndex += 1;
    },
  },
});

export const selectSmartAgreement = (state) => state.invoices.smartAgreement;
export const selectInvoice = (state) => state.invoices.invoice;
export const selectInvoices = (state) => state.invoices.invoices;
export const selectModalVisible = (state) => state.invoices.modalVisible;
export const selectInvoicesPaymentHistory = (state) =>
  state.invoices.invoicesPaymentHistory;
export const selectPendingInvoices = (state) => state.invoices.pendingInvoices;
export const selectPendingInvoiceIndex = (state) =>
  state.invoices.pendingInvoiceIndex;

export const {
  setSmartAgreement,
  setInvoice,
  setInvoices,
  addInvoiceFile,
  removeInvoiceFile,
  setModalVisible,
  setInvoicesPaymentHistory,
  setPendingInvoices,
  incrementPendingInvoicesIndex,
} = invoicesSlice.actions;

export const executeSmartActionThunk = createAsyncThunk(
  "invoices/executeSmartActionStatus",
  async ({ smartAgreementId, smartActionId, invoice }, thunkAPI) => {
    const { fieldsManuallyOverridden } = invoice;
    const response = await executeSmartAction(smartAgreementId, invoice);
    if (!thunkAPI.signal.aborted) {
      const { context, fieldsSetBySmartActions, errorContext } = response;
      // don't set fields into the invoice that have already been manually overridden
      const contextAdjustedForUserOverrides = Object.keys(context).reduce(
        (acc, fieldName) => {
          if (!fieldsManuallyOverridden[fieldName]) {
            acc[fieldName] = context[fieldName];
          }
          return acc;
        },
        {}
      );
      thunkAPI.dispatch(
        setInvoice({
          ...invoice,
          data: {
            ...invoice.data,
            ...contextAdjustedForUserOverrides,
          },
          fieldsSetBySmartActions: {
            ...invoice.fieldsSetBySmartActions,
            ...fieldsSetBySmartActions.reduce((acc, val) => {
              // retain the most recent value set by the smart action
              // so that it can be easily restored if an override is cleared
              acc[val] = context[val];
              return acc;
            }, {}),
          },
          errors: errorContext,
        })
      );
    }
    return response.data;
  }
);

export default invoicesSlice.reducer;
