import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smartActions: [],
};

export const smartActionSlice = createSlice({
  name: "smartActions",
  initialState,
  reducers: {
    setSmartActions: (state, action) => {
      state.smartActions = action.payload;
    },
  },
});

export const selectSmartActions = (state) => state.smartActions.smartActions;

export const { setSmartActions } = smartActionSlice.actions;

export default smartActionSlice.reducer;
