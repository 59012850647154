import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Stack, Typography } from "@mui/material";
import "../index.css";

const LandingPageView = () => {
  return (
    <Stack
      position={"fixed"}
      height={"100vh"}
      width={"100vw"}
      sx={{ p: { xs: 1, md: 5 } }}
      direction="column"
      justifyContent="space-between"
      boxSizing={"border-box"}
    >
      <Stack width="fit-content">
        <Typography
          variant="h1"
          p="1px"
          bgcolor="rgb(27, 228, 109)"
          fontFamily="'Space Grotesk', sans-serif"
          textTransform="uppercase"
        >
          TermCo
        </Typography>
        <Typography variant="h4" fontFamily="'Space Grotesk', sans-serif">
          The Smart Agreement Platform
        </Typography>
      </Stack>
      <Stack alignItems={"center"} width={"100%"}>
        <Typography variant="h5" fontFamily="'Space Grotesk', sans-serif">
          coming soon...
        </Typography>
      </Stack>
      <Stack alignItems={"center"} width={"100%"}>
        <Link href="mailto:bstouffer@dapprio.com">
          Learn More <FontAwesomeIcon icon={faEnvelope} />
        </Link>
      </Stack>
    </Stack>
  );
};

export default LandingPageView;
