import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smartAgreements: [],
  smartAgreementEditing: null,
  maskActive: false,
  sectionIndex: 0,
  smartAgreementsWithPendingInvoices: { smartAgreements: [], counts: {} },
};

export const smartAgreementSlice = createSlice({
  name: "smartAgreements",
  initialState,
  reducers: {
    setSmartAgreements: (state, action) => {
      state.smartAgreements = action.payload;
    },
    setSmartAgreementEditing: (state, action) => {
      state.smartAgreementEditing = action.payload;
    },
    setMaskActive: (state, action) => {
      state.maskActive = action.payload;
    },
    addSmartAction: (state, action) => {
      state.smartAgreementEditing.smartActions.push(action.payload);
    },
    setSectionIndex: (state, action) => {
      state.sectionIndex = action.payload;
    },
    updateSmartActionParam: (state, action) => {
      const { id, paramName, value } = action.payload;
      const smartAction = state.smartAgreementEditing.smartActions.find(
        (sa) => sa.id === id
      );
      smartAction.params[paramName].value = value;
    },
    addCustomAgreementFields: (state, action) => {
      state.smartAgreementEditing.customAgreementFields =
        state.smartAgreementEditing.customAgreementFields || [];
      state.smartAgreementEditing.customAgreementFields.push(action.payload);
    },
    addCustomInvoiceFields: (state, action) => {
      state.smartAgreementEditing.customInvoiceFields =
        state.smartAgreementEditing.customInvoiceFields || [];
      state.smartAgreementEditing.customInvoiceFields.push(action.payload);
    },
    addFile: (state, action) => {
      state.smartAgreementEditing.files =
        state.smartAgreementEditing.files || [];
      // correlate by name / overwrite
      state.smartAgreementEditing.files =
        state.smartAgreementEditing.files.filter(
          ({ displayName }) => displayName !== action.payload.displayName
        );
      state.smartAgreementEditing.files.push(action.payload);
    },
    removeFile: (state, action) => {
      state.smartAgreementEditing.files =
        state.smartAgreementEditing.files.filter(
          ({ displayName }) => displayName !== action.payload.displayName
        );
    },
    setSmartAgreementsWithPendingInvoices: (state, action) => {
      state.smartAgreementsWithPendingInvoices = action.payload;
    },
  },
});

export const selectSmartAgreements = (state) =>
  state.smartAgreements.smartAgreements;
export const selectSmartAgreementEditing = (state) =>
  state.smartAgreements.smartAgreementEditing;
export const selectMaskActive = (state) => state.smartAgreements.maskActive;
export const selectSmartActions = (state) =>
  state.smartAgreements.smartAgreementEditing?.smartActions;
export const selectSectionIndex = (state) => state.smartAgreements.sectionIndex;
export const selectCustomAgreementFields = (state) =>
  state.smartAgreements.smartAgreementEditing.customAgreementFields;
export const selectCustomInvoiceFields = (state) =>
  state.smartAgreements.smartAgreementEditing.customInvoiceFields;
export const selectFiles = (state) =>
  state.smartAgreements.smartAgreementEditing.files;
export const selectSmartAgreementsWithPendingInvoices = (state) =>
  state.smartAgreements.smartAgreementsWithPendingInvoices;

export const {
  setSmartAgreements,
  setSmartAgreementEditing,
  setMaskActive,
  addSmartAction,
  setSectionIndex,
  updateSmartActionParam,
  addCustomAgreementFields,
  addCustomInvoiceFields,
  addFile,
  removeFile,
  setSmartAgreementsWithPendingInvoices,
} = smartAgreementSlice.actions;

export default smartAgreementSlice.reducer;
