import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { demoValidateInvoices, getGasPrices } from "../api";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import "../index.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DemoView = () => {
  const [files, setFiles] = useState([]);
  const [fileResponses, setFileResponses] = useState(new Map());
  const [gasPrices, setGasPrices] = useState();

  useEffect(() => {
    // get live gas price
    const gasPrices = async () => {
      try {
        const prices = await getGasPrices();
        setGasPrices(prices.response.data);
      } catch (error) {
        console.log(error);
      }
    };
    gasPrices();
  }, []);

  const validate = async (files) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    const response = await demoValidateInvoices(formData);
    console.log(response);

    for (const job of response.results) {
      if (job.status === "fulfilled")
        fileResponses.set(job.value.filename, job.value.parsed[0]);
    }
    setFileResponses(new Map(fileResponses));
  };

  const invoiceDataMap = new Map();

  for (const [filename, fileResponse] of fileResponses.entries()) {
    const entities = fileResponse.document.entities;

    const invoiceDate = entities.find(
      (entity) => entity.type === "invoice-date"
    );
    const routeDate = entities.find((entity) => entity.type === "route-date");
    const gallons = entities.find((entity) => entity.type === "gallons");
    const gasTotalPrice = entities.find(
      (entity) => entity.type === "gas-total-price"
    );
    const mileage = entities.find((entity) => entity.type === "mileage");
    const total = entities.find((entity) => entity.type === "total");

    const gasPricing = gasPrices.filter(
      (gasPricing) =>
        new Date(gasPricing.period) < new Date(routeDate.normalizedValue.text)
    );
    const gasPrice = parseFloat(
      gasPrices.filter(
        (gasPricing) =>
          new Date(gasPricing.period) < new Date(routeDate.normalizedValue.text)
      )[0].value
    );

    const gasSurcharge = Math.floor(gasPrice / 0.055) * 0.01;

    const approved =
      parseFloat(gasTotalPrice.mentionText.substring(1)) <
      gasSurcharge * mileage.mentionText;

    invoiceDataMap.set(filename, {
      invoiceDate,
      routeDate,
      gallons,
      gasTotalPrice,
      mileage,
      total,
      approved,
      gasPrice,
      gasSurcharge,
    });
  }

  let approvedTotal = 0;
  for (const invoiceData of invoiceDataMap.values()) {
    if (invoiceData.approved) {
      approvedTotal =
        approvedTotal +
        parseFloat(invoiceData.total.mentionText.substring(1).replace(",", ""));
    }
  }

  return (
    <Stack
      position={"fixed"}
      height={"100vh"}
      width={"100vw"}
      sx={{ p: { xs: 3, md: 5 } }}
      direction="column"
      alignItems="center"
      boxSizing={"border-box"}
    >
      <Stack sx={{ width: { xs: 1, md: "50em" } }}>
        <Typography variant="h3">Fuel Surcharge Validator</Typography>
        {gasPrices ? (
          <Typography variant="body1">Loaded gas prices</Typography>
        ) : null}
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Process invoices
          <VisuallyHiddenInput
            type="file"
            accept=".pdf"
            onChange={(event) => {
              setFiles([...files, ...event.target.files]);
              validate(event.target.files);
            }}
            multiple
          />
        </Button>
        <Stack>
          {files
            ? [...files].map((file) => {
                const invoiceData = invoiceDataMap.get(file.name);
                return (
                  <Tooltip
                    title={
                      invoiceData ? (
                        <>
                          <Typography variant="body2">{file.name}</Typography>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">
                              Date Driven:
                            </Typography>
                            <Typography variant="body2">{` ${invoiceData.routeDate.normalizedValue.text}`}</Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">Gas Price:</Typography>
                            <Typography variant="body2">{` ${invoiceData.gasPrice}`}</Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">
                              Gas Surcharge:
                            </Typography>
                            <Typography variant="body2">{` ${invoiceData.gasSurcharge.toFixed(
                              3
                            )}`}</Typography>
                          </Stack>
                          <Stack direction="row">
                            <Typography variant="body2">{`${
                              invoiceData.mileage.mentionText
                            } mi @ ${invoiceData.gasSurcharge.toFixed(
                              3
                            )}/mi Surcharge = $${(
                              parseFloat(invoiceData.mileage.mentionText) *
                              invoiceData.gasSurcharge
                            ).toFixed(2)}`}</Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">
                              Total Charge:
                            </Typography>
                            <Typography variant="body2">{`${invoiceData.total.mentionText}`}</Typography>
                          </Stack>
                        </>
                      ) : null
                    }
                    followCursor
                    arrow
                  >
                    <Stack
                      my={1}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Stack direction="row">
                        {invoiceData ? (
                          <Stack
                            direction="row"
                            width="10em"
                            alignItems="center"
                          >
                            {invoiceData.approved ? (
                              <>
                                <CheckIcon color="success" />
                                <Chip color="success" label="Approved" />
                              </>
                            ) : (
                              <>
                                <ErrorIcon color="error" />
                                <Chip color="error" label="Needs Review" />
                              </>
                            )}
                          </Stack>
                        ) : null}
                        <Typography variant="h6">{file.name}</Typography>
                      </Stack>
                      {invoiceData ? (
                        <Typography
                          variant="body1"
                          sx={{
                            p: 1,
                            border: 2,
                            color: invoiceData.approved ? "green" : "red",
                          }}
                        >
                          {invoiceData.total.mentionText}
                        </Typography>
                      ) : null}
                    </Stack>
                    <Divider />
                  </Tooltip>
                );
              })
            : null}
          <Typography variant="h6" my={1} align="right">
            {fileResponses.size > 0
              ? `Approved Total: $${approvedTotal.toFixed(2)}`
              : null}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DemoView;
